<template>
  <div :id="radio == 1 ? 'login_corentercomBg' : 'login_bnd'">
    <div v-title :data-title="$t('message.about.title')"></div>
    <div class="language">
      <span :class="$store.state.language == 'zh' ? 'on' : ''" @click="setLanguage('zh')">中文</span>
      <span :class="$store.state.language == 'en' ? 'on' : ''" @click="setLanguage('en')">English</span>
    </div>
    <div class="right">
      <div style="width:100%;text-align: center;">
        <img class="img" :src="tabArray[radio].url" alt="" />
      </div>
      <div class="box">
        <div class="math">
          <div class="top">
            {{ $t('message.about.title') }}
            <!-- <el-radio-group class="top" v-model="radio" @change="changeValue()">
              <el-radio :class="['el_radio', item.css]" v-for="item in tabArray" :key="item.id" v-model="item.show"
                :text-color="item.color" :fill="item.color" :label="item.id">
                <img class="img" :style="item.style" :src="item.indexUrl" alt="" />
              </el-radio>
            </el-radio-group> -->
          </div>
          <div class="el_form">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
              <el-form-item prop="username">
                <el-input type="text" :placeholder="$t('message.login.account')" @input="bindInput" maxlength="15"
                  v-model.trim="ruleForm.username">
                </el-input>
                <!-- autocomplete="on" -->
              </el-form-item>
              <el-form-item prop="password">
                <el-input type="password" v-model="ruleForm.password" autocomplete="new-password"
                  :placeholder="$t('message.login.password')">
                </el-input>
                <!-- autocomplete="on" -->
              </el-form-item>
              <el-form-item>
                <el-button @click="submitForm('ruleForm')" v-preventReClick
                  class="login-submit">{{ $t('message.login.Login') }}</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="bottom">
            <div>
              {{ $t('message.login.rememberme') }}？
              <el-switch v-model="checked" active-color="#2776c3" inactive-color="#838383">
              </el-switch>
            </div>
            <a class="el_form_a" :href="xzURL">{{ $t('message.login.installationServices') }}</a>
          </div>
        </div>
      </div>
      <div class="development">
        <div>{{ $t('message.about.conpanyname') }}</div>
        <div style="margin-top: 10px">v1.6.1</div>
      </div>
    </div>
  </div>
</template>

<script></script>
<script>
import { Login } from "@/administration/login.js";
import regfunc from "@/method/form/regfunc.js";
import EventBus from "../eventBus/eventBus.js";
import beasconfig from "@/api/beasconfig.js";
// var ws;
export default {
  data() {
    var username = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t('message.message.loginAccountTips')));
      } if (!regfunc.rNum.test(value)) {
        callback(new Error(this.$t('message.message.accountNumbers')));
      } else {
        callback();
      }
    };
    var password = (rule, value, callback) => {
      if (value == "") {
        callback(new Error(this.$t('message.message.loginPasswordTips')));
      } else {
        callback();
      }
    };
    return {
      checked: false,
      ruleForm: {
        //账号密码
        username: "", //账号
        password: "", //密码
      },
      radio: 1, //选择tab的下标
      rules: {
        username: [{ validator: username, trigger: "blur" }],
        password: [{ validator: password, trigger: "blur" }],
      },
      tabArray: [
        {
          id: 0,
          url: require("@/assets/login/bonardDesk.png"),
          indexUrl: require("@/assets/login/bonardptt.png"),
          color: "#ffba17",
          style: "width: 155px;height: 35px;",
          show: false,
          css:'tab1'
        },
        {
          id: 1,
          url: require("@/assets/login/coreintercomDesk.png"),
          indexUrl: require("@/assets/login/coreintercom.png"),
          color: "var(--main-color)",
          style: "width: 138px;height: 35px;",
          show: false,
          css:'tab2'
        },
      ],
      xzURL:beasconfig.xinextendUrlapi, //安装包路径
      isInstall:false
    };
  },
  created() {
    //记住密码判断是否记住密码 记住密码则在一开始就渲染上去
    this.xzURL = `${beasconfig.xinextendUrlapi}/dispatcher.exe?${Math.random()}`;
    let userNumber = JSON.parse(localStorage.getItem("userNumber"));
    if (userNumber && userNumber.checked) {
      this.ruleForm.username = userNumber.userName;
      this.ruleForm.password = userNumber.password;
      this.checked = true;
    }
    this.$forceUpdate();
    sessionStorage.removeItem('ballId');
    this.$store.state.ballId = 1;
  },
  // beforeUpdate() {
  //   console.log('----beforeUpdate----')
  // },
  mounted() {
    
  },
  methods: {
    setLanguage(val) {
      if(this.$store.state.language == val){

        return
      } 
      localStorage.setItem('languageStorage', val);
      this.$store.state.language = val;
      this.$i18n.locale = val;
      document.title = this.$t('message.about.title');
    },
    bindInput(value) {        //不能输入
      this.$forceUpdate();
      this.ruleForm.username = value.replace(/[^\d]/g, '')
      this.$store.state.userName = this.ruleForm.username;
      // let reg = /^[0-9]{1,15}$/g;
      // let n = value.search(reg);
      // if (n === -1 && value.length > 1) {
      //   this.ruleForm.username = value.slice(0, n);
      // }
    },
    submitForm(formName) {
      //登录
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let _this = this;
          if(_this.$store.state.isLinkSuccess){
            let data = {
            userName: _this.ruleForm.username.trim(),
            password: _this.ruleForm.password.trim(),
            version: 2.1,
          };
          Login(data)
            .then((res) => {
              if (res.data.code == 200) {
                console.log(res.data)
                if (res.data.data.User_Type != 3) {
                  _this.$MessageWarning(_this.$t('message.message.noDispatcher'));
                } else if (res.data.data.User_Enable == 1) {  //判断是否停用
                  sessionStorage.setItem(
                    "userInfo",
                    JSON.stringify(res.data.data)
                  );
                  sessionStorage.setItem("userNumber", JSON.stringify(data));
                  data.checked = _this.checked;
                  localStorage.setItem("userNumber", JSON.stringify(data));
                  EventBus.$emit('login')
                } else {
                  _this.$MessageWarning(_this.$t('message.message.accountNotpresent'));
                }
              }else if(res.data.code == 4001){
                _this.$MessageWarning(_this.$t('message.message.usernamePasswordTips'));
              } else {
                _this.$MessageWarning(res.data.msg)
              }
            })
            .catch((error) => {
              // console.log(error); //请求失败返回的数据
              _this.$MessageWarning(_this.$t('message.message.LoginAbnormal'))
            });
          }else{
            _this.$MessageWarning(_this.$t('message.message.voiceServiceNotInstalled'));
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeValue() {
      this.radio = 1;
    }
  },
  beforeRouteEnter(to, from, next) {   //监听浏览器回退 回退到登录页面就退出登录
        next(vm => {
            if(from.name != null && from.name != "Login"){
              EventBus.$emit("changePassword");
            }
        })
    },
};
</script>

<style lang="less" scoped >
#login_bnd {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: url(../assets/login/bonardBg.jpg);
  background-size: 100% 100%;
}

#login_corentercomBg {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: url(../assets/login/coreintercomBg.png);
  background-size: 100% 100%;
}

.right {
  margin-left: 60vw;

  .img {
    position: relative;
    z-index: 10;
    // width: 356px;
    height: 73px;
    margin-bottom: 20px;
  }

  .box {
    width: 410px;
    // width: 439px;
    // height: 433px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid var(--main-color);

    .math {
      width: 85%;
      margin: auto;
    }

    .el_form {
      margin: 0px 0px;

      .login-submit {
        width: 100%;
        color: #ffffff;
        background-color: var(--main-color);
        font-size: 20px;
        font-weight: bold;
      }
    }

    .top {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 35px;
      font-weight: bold;
      font-family: "Microsoft YaHei";
      color: var(--main-color);
      // border-bottom: 1px solid var(--main-color);

      .el_radio {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .bottom {
      color: #838383;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 40px 0px;
    }
  }

  .development {
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    margin-top: 30px;
  }

  .el_form_a {
    color: red;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: bold;
  }
}

.tab1 {
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border: 1px solid #ffba17;
    border-color: #ffba17;
    background-color: #ffffff;
  }

  /deep/.el-radio__inner {
    border: 1px solid #ffba17;
  }

  /deep/ .el-radio__inner::after {
    width: 6px;
    height: 6px;
    background-color: #ffba17 !important;
  }
}


.tab2 {
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: var(--main-color);
    background-color: #ffffff;

  }

  /deep/.el-radio__inner {
    border: 1px solid var(--main-color);
  }

  /deep/ .el-radio__inner::after {
    width: 6px;
    height: 6px;
    background-color: var(--main-color) !important;
  }
}

.language {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px 30px;
}

.language span {
  display: inline-block;
  color: #606266;
  cursor: pointer;
  padding: 0 30px;
}

.language span:first-child {
  border-right: 1px solid #fff;
}

.language span.on {
  cursor: default;
  color: #fff;
}
</style>